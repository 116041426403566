var render = function render(){var _vm=this,_c=_vm._self._c;return _c('SatQuestionViewer',{attrs:{"disableDelete":_vm.disableDelete,"disabledAction":_vm.disabledAction,"displayCorrectAnswer":_vm.displayCorrectAnswer,"displayOptions":_vm.displayOptions,"question":_vm.question,"viewPath":_vm.$router.resolve({ name: 'Question', query: { questionId: _vm.question.id } }).href,"editPath":_vm.$router.resolve({
      name: 'EditQuestion',
      query: { examId: _vm.examId, questionId: _vm.question.id }
    }).href,"passagePath":_vm.$router.resolve({
      name: 'Passage',
      query: { examId: _vm.examId, questionId: _vm.question.sat_passage_id }
    }).href},on:{"onDeleteQuestion":() => _vm.deleteQuestion(_vm.question.id)}})
}
var staticRenderFns = []

export { render, staticRenderFns }